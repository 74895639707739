.img-crop-modal .img-crop-container {
    position: relative;
    width: 100%;
    height: 40vh;
}
.img-crop-modal .img-crop-control {
    display: flex;
    align-items: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.img-crop-modal .img-crop-control:first-of-type {
    margin-top: 16px;
}
.img-crop-modal .img-crop-control:last-of-type {
    margin-bottom: -8px;
}
.img-crop-modal .img-crop-control button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    padding: 0;
    font-style: normal;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
}
.img-crop-modal .img-crop-control button[disabled] {
    cursor: default;
}
.img-crop-modal .img-crop-control button + div:only-of-type {
    flex: 1;
    margin: 0 8px;
}
.img-crop-modal .img-crop-control-zoom button {
    font-size: 18px;
}
.img-crop-modal .img-crop-control-rotate button {
    font-size: 16px;
}
.img-crop-modal .img-crop-control-rotate button:first-of-type {
    transform: rotate(-20deg);
}
.img-crop-modal .img-crop-control-rotate button:last-of-type {
    transform: rotate(20deg);
}
